import Floor1 from "../shared/images/floors/План 1 этаж/image 11.png";
import Floor2 from "../shared/images/floors/План/3 этаж/image 16.png";
import Floor4 from "../shared/images/floors/План/4 этаж/image 15.png";
import Floor8 from "../shared/images/floors/План 8 этаж/план 9 этажа/планировка 9 этажа.png";
import Floor11 from "../shared/images/floors/План 11 этаж/image 14.png";
import Floor13 from "../shared/images/floors/План 13 этаж/image 12.png";

export const floors = [
  {
    rigth: { path: "M 431 167 L 431 124 L 196 269 L 196 302 Z" },
    left: { path: "M 573 247 L 573 213 L 431 124 L 431 167 Z" },
    label: "Horosho",
    floor: 13,
    floorsImages: [
      {
        path: "M0 0H763V161H0V0Z",
        x: 10,
        y: 357,
        width: 763,
        height: 161,
        scale: "0.73",
      },
      {
        path: "M0 191H266V182.5H417.5V191H595.5V0H795V188.5H754.5V328H795V508L793 514L790 517.5L785.5 521.5L780 523H489.5V330H0V191Z",
        x: 213,
        y: 109,
        width: 795,
        height: 523,
        scale: "0.73",
      },
    ],
    squints: {
      1: { square: 217.94, name: "Ресторан на терассе", lock: true },
      2: { square: 351.12, name: "Ресторан", lock: true },
    },
    count: 2,
    image: Floor13,
  },
  {
    rigth: { path: "M 431.5 198 L 431.5 166 L 196 302 L 196 325 Z" },
    left: { path: "M 573 272 L 573 247 L 431 166 L 431 198 Z" },
    label: "2 этаж",
    floor: 12,
    floorsImages: [
      {
        path: "M0 0H287V32.7144H201L199 499H0V325.5H42V186.5H0V0Z",
        x: 13,
        y: 110,
        width: 287,
        height: 499,
        scale: "0.73",
      },
      {
        path: "M0 0H328V256H0V0Z",
        x: 163,
        y: 290,
        width: 328,
        height: 256,
        scale: "0.73",
      },
      {
        path: "M0 0H320V94.5H324V278H228.5V257H0V0Z",
        x: 407,
        y: 287,
        width: 324,
        height: 278,
        scale: "0.73",
      },
      {
        path: "M0 0H202V196.5H209V318.5H202V502.5L200 509L197 515L189 519H181H0V0Z",
        x: 647,
        y: 110,
        width: 209,
        height: 519,
        scale: "0.73",
      },
    ],
    squints: {
      1: { square: 165.2, name: "Офис №1", lock: true },
      2: { square: 140.53, name: "Офис №2", lock: true },
      3: { square: 143.73, name: "Офис №3", lock: true },
      4: { square: 175.81, name: "Офис №4", lock: true },
    },
    count: 4,
    image: Floor11,
  },
  {
    rigth: { path: "M 431 231 L 431 198 L 196 325 L 196 346 Z" },
    left: { path: "M 573 298 L 573 272 L 431 198 L 431 231 Z" },
    label: "Horosho",
    floor: 11,
    floorsImages: [
      {
        path: "M0 0H287V32.7144H201L199 499H0V325.5H42V186.5H0V0Z",
        x: 13,
        y: 110,
        width: 287,
        height: 499,
        scale: "0.73",
      },
      {
        path: "M0 0H328V256H0V0Z",
        x: 163,
        y: 290,
        width: 328,
        height: 256,
        scale: "0.73",
      },
      {
        path: "M0 0H320V94.5H324V278H228.5V257H0V0Z",
        x: 407,
        y: 287,
        width: 324,
        height: 278,
        scale: "0.73",
      },
      {
        path: "M0 0H202V196.5H209V318.5H202V502.5L200 509L197 515L189 519H181H0V0Z",
        x: 647,
        y: 110,
        width: 209,
        height: 519,
        scale: "0.73",
      },
    ],
    squints: {
      1: { square: 165.2, name: "Офис №1", lock: true },
      2: { square: 140.53, name: "Офис №2", lock: true },
      3: { square: 143.73, name: "Офис №3", lock: true },
      4: { square: 175.81, name: "Офис №4", lock: true },
    },
    count: 4,
    image: Floor11,
  },
  {
    rigth: { path: "M 431 263 L 431 230.5 L 196 346 L 196 367 Z" },
    left: { path: "M 573 323 L 573 298 L 431 231 L 431 263 Z" },
    label: "Horosho",
    floor: 10,
    floorsImages: [
      {
        path: "M0 0H229H313.5V36.5H229V205H0V0Z",
        x: 7,
        y: 107,
        width: 314,
        height: 205,
        scale: "0.7",
      },
      {
        path: "M40 0.5H217V261H0V71.6569H40V0.5Z",
        x: 7,
        y: 295,
        width: 217,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H176V280H0V0Z",
        x: 160,
        y: 283,
        width: 176,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 11H133.5V0H173V11V280H0V11Z",
        x: 283,
        y: 283,
        width: 173,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H176V280H0V0Z",
        x: 403,
        y: 283,
        width: 170,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0L221 0.5V88H175V280V304H70.5V280H0V0Z",
        x: 523,
        y: 283,
        width: 221,
        height: 304,
        scale: "0.69",
      },
      {
        path: "M0 0H220V191L218 197.5L214.5 203L206 208.5L201.5 209H197H0V0Z",
        x: 644,
        y: 348,
        width: 220,
        height: 209,
        scale: "0.69",
      },
      {
        path: "M6.5 0H221V205L6.5 204V36.5H0V0H6.5Z",
        x: 644,
        y: 105,
        width: 221,
        height: 205,
        scale: "0.69",
      },
    ],
    squints: {
      1: { square: 77.69, name: "Офис №1", lock: true },
      2: { square: 76.59, name: "Офис №2", lock: true },
      3: { square: 71.7, name: "Офис №3", lock: true },
      4: { square: 68.7, name: "Офис №4", lock: true },
      5: { square: 70.1, name: "Офис №5", lock: true },
      6: { square: 79.97, name: "Офис №6", lock: true },
      7: { square: 102.13, name: "Офис №7", lock: true },
      8: { square: 63.83, name: "Офис №8", lock: true },
    },
    count: 8,
    image: Floor8,
  },
  {
    rigth: { path: "M 431 295 L 431 263 L 196 367 L 196 387 Z" },
    left: { path: "M 573 349 L 573 323 L 431 263 L 431 295 Z" },
    label: "Horosho",
    floor: 9,
    floorsImages: [
      {
        path: "M0 0H229H313.5V36.5H229V205H0V0Z",
        x: 7,
        y: 107,
        width: 314,
        height: 205,
        scale: "0.7",
      },
      {
        path: "M40 0.5H217V261H0V71.6569H40V0.5Z",
        x: 7,
        y: 295,
        width: 217,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H176V280H0V0Z",
        x: 160,
        y: 283,
        width: 176,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 11H133.5V0H173V11V280H0V11Z",
        x: 283,
        y: 283,
        width: 173,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H176V280H0V0Z",
        x: 403,
        y: 283,
        width: 170,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0L221 0.5V88H175V280V304H70.5V280H0V0Z",
        x: 523,
        y: 283,
        width: 221,
        height: 304,
        scale: "0.69",
      },
      {
        path: "M0 0H220V191L218 197.5L214.5 203L206 208.5L201.5 209H197H0V0Z",
        x: 644,
        y: 348,
        width: 220,
        height: 209,
        scale: "0.69",
      },
      {
        path: "M6.5 0H221V205L6.5 204V36.5H0V0H6.5Z",
        x: 644,
        y: 105,
        width: 221,
        height: 205,
        scale: "0.69",
      },
    ],
    squints: {
      1: { square: 77.69, name: "Офис №1", lock: true },
      2: { square: 76.59, name: "Офис №2", lock: true },
      3: { square: 71.7, name: "Офис №3", lock: true },
      4: { square: 68.7, name: "Офис №4", lock: true },
      5: { square: 70.1, name: "Офис №5", lock: true },
      6: { square: 79.97, name: "Офис №6", lock: true },
      7: { square: 102.13, name: "Офис №7", lock: true },
      8: { square: 63.83, name: "Офис №8", lock: true },
    },
    count: 8,
    image: Floor8,
  },
  {
    rigth: { path: "M 431 327 L 431 295 L 196 387 L 196 408 Z" },
    left: { path: "M 573 375 L 573 349 L 431 295 L 431 327 Z" },
    label: "Horosho",
    floor: 8,
    floorsImages: [
      {
        path: "M0 0H229H313.5V36.5H229V205H0V0Z",
        x: 7,
        y: 107,
        width: 314,
        height: 205,
        scale: "0.7",
      },
      {
        path: "M40 0.5H217V261H0V71.6569H40V0.5Z",
        x: 7,
        y: 295,
        width: 217,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H176V280H0V0Z",
        x: 160,
        y: 283,
        width: 176,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 11H133.5V0H173V11V280H0V11Z",
        x: 283,
        y: 283,
        width: 173,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H176V280H0V0Z",
        x: 403,
        y: 283,
        width: 170,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0L221 0.5V88H175V280V304H70.5V280H0V0Z",
        x: 523,
        y: 283,
        width: 221,
        height: 304,
        scale: "0.69",
      },
      {
        path: "M0 0H220V191L218 197.5L214.5 203L206 208.5L201.5 209H197H0V0Z",
        x: 644,
        y: 348,
        width: 220,
        height: 209,
        scale: "0.69",
      },
      {
        path: "M6.5 0H221V205L6.5 204V36.5H0V0H6.5Z",
        x: 644,
        y: 105,
        width: 221,
        height: 205,
        scale: "0.69",
      },
    ],
    squints: {
      1: { square: 77.69, name: "Офис №1", lock: true },
      2: { square: 76.59, name: "Офис №2", lock: true },
      3: { square: 71.7, name: "Офис №3", lock: true },
      4: { square: 68.7, name: "Офис №4", lock: true },
      5: { square: 70.1, name: "Офис №5", lock: true },
      6: { square: 79.97, name: "Офис №6", lock: true },
      7: { square: 102.13, name: "Офис №7", lock: true },
      8: { square: 63.83, name: "Офис №8", lock: true },
    },
    count: 8,
    image: Floor8,
  },
  {
    rigth: { path: "M 431 360 L 431 327 L 196 408 L 196 430 Z" },
    left: { path: "M 573 400 L 573 375 L 431 327 L 431 360 Z" },
    label: "Horosho",
    floor: 7,
    floorsImages: [
      {
        path: "M0 0L178.5 0.5V37H86.5V197H0V0Z",
        x: 100,
        y: 107,
        width: 179,
        height: 197,
        scale: "0.7",
      },
      {
        path: "M0 0H125V200H91.7346V197H29V204.5H0V0Z",
        x: 11,
        y: 106,
        width: 125,
        height: 205,
        scale: "0.7",
      },
      {
        path: "M38.5 0H118V261H0V71.5H38.5V0Z",
        x: 12,
        y: 296,
        width: 118,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H90V261H0V0Z",
        x: 96,
        y: 296,
        width: 90,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 162,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 224,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 11.0001H134V0H173V11.0001V281H0V31.2864V11.0001Z",
        x: 284,
        y: 284,
        width: 173,
        height: 281,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 405,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 465,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H134V98.5H174.5V302.5H70V279.5L0 280.5V0Z",
        x: 524,
        y: 284,
        width: 175,
        height: 303,
        scale: "0.7",
      },
      {
        path: "M0 0H77.5V95.5H180.5V99.5H210V103H229.5V91.5H258V286L257.5 292.5L253.5 299.5L245.5 303.5H237H39V88.5H0V0Z",
        x: 618,
        y: 282,
        width: 258,
        height: 304,
        scale: "0.7",
      },
      {
        path: "M0 0H127.5V6.5H178V138.756H141.5H119.735V145.192H0V0Z",
        x: 676,
        y: 245,
        width: 178,
        height: 146,
        scale: "0.7",
      },
      {
        path: "M0 0H223V204.5H193.5V193H174.5V196.5H144V201.5H7.5V56H11.5V37H0V0Z",
        x: 643,
        y: 108,
        width: 273,
        height: 205,
        scale: "0.7",
      },
    ],
    squints: {
      1: { square: 30.07, name: "Офис №1", lock: true },
      2: { square: 36.58, name: "Офис №2", lock: true },
      3: { square: 39.58, name: "Офис №3", lock: true },
      4: { square: 34.04, name: "Офис №4", lock: true },
      5: { square: 33.82, name: "Офис №5", lock: true },
      6: { square: 34.69, name: "Офис №6", lock: true },
      7: { square: 68.7, name: "Офис №7", lock: true },
      8: { square: 33.98, name: "Офис №8", lock: true },
      9: { square: 33.45, name: "Офис №9", lock: true },
      10: { square: 68.85, name: "Офис №10", lock: true },
      11: { square: 76.83, name: "Офис №11", lock: true },
      12: { square: 35.4, name: "Офис №12", lock: true },
      13: { square: 63.83, name: "Офис №13", lock: true },
    },
    count: 13,
    image: Floor4,
  },
  {
    rigth: { path: "M 431 393 L 431 360 L 196 430 L 196 450 Z" },
    left: { path: "M 573 426 L 573 400 L 431 360 L 431 393 Z" },
    label: "Horosho",
    floor: 6,
    floorsImages: [
      {
        path: "M0 0L178.5 0.5V37H86.5V197H0V0Z",
        x: 100,
        y: 107,
        width: 179,
        height: 197,
        scale: "0.7",
      },
      {
        path: "M0 0H125V200H91.7346V197H29V204.5H0V0Z",
        x: 11,
        y: 106,
        width: 125,
        height: 205,
        scale: "0.7",
      },
      {
        path: "M38.5 0H118V261H0V71.5H38.5V0Z",
        x: 12,
        y: 296,
        width: 118,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H90V261H0V0Z",
        x: 96,
        y: 296,
        width: 90,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 162,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 224,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 11.0001H134V0H173V11.0001V281H0V31.2864V11.0001Z",
        x: 284,
        y: 284,
        width: 173,
        height: 281,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 405,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 465,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H134V98.5H174.5V302.5H70V279.5L0 280.5V0Z",
        x: 524,
        y: 284,
        width: 175,
        height: 303,
        scale: "0.7",
      },
      {
        path: "M0 0H77.5V95.5H180.5V99.5H210V103H229.5V91.5H258V286L257.5 292.5L253.5 299.5L245.5 303.5H237H39V88.5H0V0Z",
        x: 618,
        y: 282,
        width: 258,
        height: 304,
        scale: "0.7",
      },
      {
        path: "M0 0H127.5V6.5H178V138.756H141.5H119.735V145.192H0V0Z",
        x: 676,
        y: 245,
        width: 178,
        height: 146,
        scale: "0.7",
      },
      {
        path: "M0 0H223V204.5H193.5V193H174.5V196.5H144V201.5H7.5V56H11.5V37H0V0Z",
        x: 643,
        y: 108,
        width: 273,
        height: 205,
        scale: "0.7",
      },
    ],
    squints: {
      1: { square: 30.07, name: "Офис №1", lock: true },
      2: { square: 36.58, name: "Офис №2", lock: true },
      3: { square: 39.58, name: "Офис №3", lock: true },
      4: { square: 34.04, name: "Офис №4", lock: true },
      5: { square: 33.82, name: "Офис №5", lock: true },
      6: { square: 34.69, name: "Офис №6", lock: true },
      7: { square: 68.7, name: "Офис №7", lock: true },
      8: { square: 33.98, name: "Офис №8", lock: true },
      9: { square: 33.45, name: "Офис №9", lock: true },
      10: { square: 68.85, name: "Офис №10", lock: true },
      11: { square: 76.83, name: "Офис №11", lock: true },
      12: { square: 35.4, name: "Офис №12", lock: true },
      13: { square: 63.83, name: "Офис №13", lock: true },
    },
    count: 13,
    image: Floor4,
  },
  {
    rigth: { path: "M 431 426 L 431 393 L 196 450 L 196 472 Z" },
    left: { path: "M 573 453 L 573 426 L 431 393 L 431 426 Z" },
    label: "Horosho",
    floor: 5,
    floorsImages: [
      {
        path: "M0 0L178.5 0.5V37H86.5V197H0V0Z",
        x: 100,
        y: 107,
        width: 179,
        height: 197,
        scale: "0.7",
      },
      {
        path: "M0 0H125V200H91.7346V197H29V204.5H0V0Z",
        x: 11,
        y: 106,
        width: 125,
        height: 205,
        scale: "0.7",
      },
      {
        path: "M38.5 0H118V261H0V71.5H38.5V0Z",
        x: 12,
        y: 296,
        width: 118,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H90V261H0V0Z",
        x: 96,
        y: 296,
        width: 90,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 162,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 224,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 11.0001H134V0H173V11.0001V281H0V31.2864V11.0001Z",
        x: 284,
        y: 284,
        width: 173,
        height: 281,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 405,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 465,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H134V98.5H174.5V302.5H70V279.5L0 280.5V0Z",
        x: 524,
        y: 284,
        width: 175,
        height: 303,
        scale: "0.7",
      },
      {
        path: "M0 0H77.5V95.5H180.5V99.5H210V103H229.5V91.5H258V286L257.5 292.5L253.5 299.5L245.5 303.5H237H39V88.5H0V0Z",
        x: 618,
        y: 282,
        width: 258,
        height: 304,
        scale: "0.7",
      },
      {
        path: "M0 0H127.5V6.5H178V138.756H141.5H119.735V145.192H0V0Z",
        x: 676,
        y: 245,
        width: 178,
        height: 146,
        scale: "0.7",
      },
      {
        path: "M0 0H223V204.5H193.5V193H174.5V196.5H144V201.5H7.5V56H11.5V37H0V0Z",
        x: 643,
        y: 108,
        width: 273,
        height: 205,
        scale: "0.7",
      },
    ],
    squints: {
      1: { square: 30.07, name: "Офис №1", lock: true },
      2: { square: 36.58, name: "Офис №2", lock: true },
      3: { square: 39.58, name: "Офис №3", lock: true },
      4: { square: 34.04, name: "Офис №4", lock: true },
      5: { square: 33.82, name: "Офис №5", lock: true },
      6: { square: 34.69, name: "Офис №6", lock: true },
      7: { square: 68.7, name: "Офис №7", lock: true },
      8: { square: 33.98, name: "Офис №8", lock: true },
      9: { square: 33.45, name: "Офис №9", lock: true },
      10: { square: 68.85, name: "Офис №10", lock: true },
      11: { square: 76.83, name: "Офис №11", lock: true },
      12: { square: 35.4, name: "Офис №12", lock: true },
      13: { square: 63.83, name: "Офис №13", lock: true },
    },
    count: 13,
    image: Floor4,
  },
  {
    rigth: { path: "M 431 458 L 431 426 L 196 472 L 196 494 Z" },
    left: { path: "M 573 480 L 573 454 L 431 426 L 431 458 Z" },
    label: "Horosho",
    floor: 4,
    floorsImages: [
      {
        path: "M0 0L178.5 0.5V37H86.5V197H0V0Z",
        x: 100,
        y: 107,
        width: 179,
        height: 197,
        scale: "0.7",
      },
      {
        path: "M0 0H125V200H91.7346V197H29V204.5H0V0Z",
        x: 11,
        y: 106,
        width: 125,
        height: 205,
        scale: "0.7",
      },
      {
        path: "M38.5 0H118V261H0V71.5H38.5V0Z",
        x: 12,
        y: 296,
        width: 118,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H90V261H0V0Z",
        x: 96,
        y: 296,
        width: 90,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 162,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 224,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 11.0001H134V0H173V11.0001V281H0V31.2864V11.0001Z",
        x: 284,
        y: 284,
        width: 173,
        height: 281,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 405,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H83V279.5H0V21V0Z",
        x: 465,
        y: 284,
        width: 83,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H134V98.5H174.5V302.5H70V279.5L0 280.5V0Z",
        x: 524,
        y: 284,
        width: 175,
        height: 303,
        scale: "0.7",
      },
      {
        path: "M0 0H77.5V95.5H180.5V99.5H210V103H229.5V91.5H258V286L257.5 292.5L253.5 299.5L245.5 303.5H237H39V88.5H0V0Z",
        x: 618,
        y: 282,
        width: 258,
        height: 304,
        scale: "0.7",
      },
      {
        path: "M0 0H127.5V6.5H178V138.756H141.5H119.735V145.192H0V0Z",
        x: 676,
        y: 245,
        width: 178,
        height: 146,
        scale: "0.7",
      },
      {
        path: "M0 0H223V204.5H193.5V193H174.5V196.5H144V201.5H7.5V56H11.5V37H0V0Z",
        x: 643,
        y: 108,
        width: 273,
        height: 205,
        scale: "0.7",
      },
    ],
    squints: {
      1: { square: 30.07, name: "Офис №1", lock: true },
      2: { square: 36.58, name: "Офис №2", lock: true },
      3: { square: 39.58, name: "Офис №3", lock: true },
      4: { square: 34.04, name: "Офис №4", lock: true },
      5: { square: 33.82, name: "Офис №5", lock: true },
      6: { square: 34.69, name: "Офис №6", lock: true },
      7: { square: 68.7, name: "Офис №7", lock: true },
      8: { square: 33.98, name: "Офис №8", lock: true },
      9: { square: 33.45, name: "Офис №9", lock: true },
      10: { square: 68.85, name: "Офис №10", lock: true },
      11: { square: 76.83, name: "Офис №11", lock: true },
      12: { square: 35.4, name: "Офис №12", lock: true },
      13: { square: 63.83, name: "Офис №13", lock: true },
    },
    count: 13,
    image: Floor4,
  },
  {
    rigth: { path: "M 431 488 L 431 458 L 196 494 L 196 514 Z" },
    left: { path: "M 573 503 L 573 480 L 431 458 L 431 488 Z" },
    label: "Horosho",
    floor: 3,
    floorsImages: [
      {
        path: "M0 0H229H313.5V36.5H229V205H0V0Z",
        x: 7,
        y: 107,
        width: 314,
        height: 205,
        scale: "0.7",
      },
      {
        path: "M40 0.5H217V261H0V71.6569H40V0.5Z",
        x: 7,
        y: 295,
        width: 217,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H176V280H0V0Z",
        x: 160,
        y: 283,
        width: 176,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 11H133.5V0H173V11V280H0V11Z",
        x: 283,
        y: 283,
        width: 173,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H176V280H0V0Z",
        x: 403,
        y: 283,
        width: 170,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0L221 0.5V88H175V280V304H70.5V280H0V0Z",
        x: 523,
        y: 283,
        width: 221,
        height: 304,
        scale: "0.69",
      },
      {
        path: "M0 0H220V191L218 197.5L214.5 203L206 208.5L201.5 209H197H0V0Z",
        x: 644,
        y: 348,
        width: 220,
        height: 209,
        scale: "0.69",
      },
      {
        path: "M6.5 0H221V205L6.5 204V36.5H0V0H6.5Z",
        x: 644,
        y: 105,
        width: 221,
        height: 205,
        scale: "0.69",
      },
    ],
    squints: {
      1: { square: 77.69, name: "Офис №1", lock: true },
      2: { square: 76.59, name: "Офис №2", lock: true },
      3: { square: 71.7, name: "Офис №3", lock: true },
      4: { square: 68.7, name: "Офис №4", lock: true },
      5: { square: 70.1, name: "Офис №5", lock: true },
      6: { square: 79.97, name: "Офис №6", lock: true },
      7: { square: 102.13, name: "Офис №7", lock: true },
      8: { square: 63.83, name: "Офис №8", lock: true },
    },
    count: 8,
    image: Floor2,
  },
  {
    rigth: { path: "M 431 513 L 431 488 L 196 514 L 196 530 Z" },
    left: { path: "M 573 521 L 573 503 L 431 488 L 431 513 Z" },
    label: "Horosho",
    floor: 2,
    floorsImages: [
      {
        path: "M0 0H229H313.5V36.5H229V205H0V0Z",
        x: 7,
        y: 107,
        width: 314,
        height: 205,
        scale: "0.7",
      },
      {
        path: "M40 0.5H217V261H0V71.6569H40V0.5Z",
        x: 7,
        y: 295,
        width: 217,
        height: 261,
        scale: "0.7",
      },
      {
        path: "M0 0H176V280H0V0Z",
        x: 160,
        y: 283,
        width: 176,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 11H133.5V0H173V11V280H0V11Z",
        x: 283,
        y: 283,
        width: 173,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0H176V280H0V0Z",
        x: 403,
        y: 283,
        width: 170,
        height: 280,
        scale: "0.7",
      },
      {
        path: "M0 0L221 0.5V88H175V280V304H70.5V280H0V0Z",
        x: 523,
        y: 283,
        width: 221,
        height: 304,
        scale: "0.69",
      },
      {
        path: "M0 0H220V191L218 197.5L214.5 203L206 208.5L201.5 209H197H0V0Z",
        x: 644,
        y: 348,
        width: 220,
        height: 209,
        scale: "0.69",
      },
      {
        path: "M6.5 0H221V205L6.5 204V36.5H0V0H6.5Z",
        x: 644,
        y: 105,
        width: 221,
        height: 205,
        scale: "0.69",
      },
    ],
    squints: {
      1: { square: 77.69, name: "Офис №1", lock: true },
      2: { square: 76.59, name: "Офис №2", lock: true },
      3: { square: 71.7, name: "Офис №3", lock: true },
      4: { square: 68.7, name: "Офис №4", lock: true },
      5: { square: 70.1, name: "Офис №5", lock: true },
      6: { square: 79.97, name: "Офис №6", lock: true },
      7: { square: 102.13, name: "Офис №7", lock: true },
      8: { square: 63.83, name: "Офис №8", lock: true },
    },
    count: 8,
    image: Floor2,
  },
  {
    rigth: { path: "M 474 583 L 474 513 L 150 534 L 150 575 Z" },
    left: { path: "M 626 581 L 626 527 L 474 514.5 L 474 583 Z" },
    label: "Horosho",
    floor: 1,
    floorsImages: [
      {
        path: "M0 0H334.5V69.5H285V127H334.5V252.5H329L328.036 496.523H182V520H43.8346H0V260H36L35 135.5H0V0Z",
        x: 10,
        y: 157,
        width: 335,
        height: 520,
        scale: "0.7",
      },
      {
        path: "M9 10H115V0H153V10V340H0L1.5 233.5L2.5 214.5V90H9V10Z",
        x: 244,
        y: 280,
        width: 153,
        height: 340,
        scale: "0.7",
      },
      {
        path: "M0 0H140V49.5H232V392H142.5V327L15 327.5H0V0Z",
        x: 351,
        y: 246,
        width: 292,
        height: 392,
        scale: "0.7",
      },
      {
        path: "M64 0H348.5L376.5 237.5L391 355.5L395.5 408L397 420.5L396.5 432.5L386 456L373 468.5L360 477L341.5 480H211.5V456H57V480H0V226H57V101H64V46.5V0Z",
        x: 518,
        y: 183,
        width: 397,
        height: 380,
        scale: "0.7",
      },
    ],
    squints: {
      1: { square: 337.77, name: "Торговое помещение №1", lock: true },
      2: { square: 84.43, name: "Кофейня", lock: true },
      3: { square: 162.86, name: "Холл", lock: true },
      4: { square: 342.66, name: "Торговое помещение №2", lock: true },
    },
    count: 3,
    image: Floor1,
  },
];
