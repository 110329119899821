export const Mic = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3596 24C26.2352 25.4015 24.8104 26.5327 23.1904 27.3099C21.5704 28.0871 19.7965 28.4906 17.9996 28.4906C16.2028 28.4906 14.4289 28.0871 12.8089 27.3099C11.1889 26.5327 9.76407 25.4015 8.63965 24"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 28.5V31.5M18 22.5C16.4087 22.5 14.8826 21.8679 13.7574 20.7426C12.6321 19.6174 12 18.0913 12 16.5V10.5C12 8.9087 12.6321 7.38258 13.7574 6.25736C14.8826 5.13214 16.4087 4.5 18 4.5V4.5C19.5913 4.5 21.1174 5.13214 22.2426 6.25736C23.3679 7.38258 24 8.9087 24 10.5V16.5C24 18.0913 23.3679 19.6174 22.2426 20.7426C21.1174 21.8679 19.5913 22.5 18 22.5Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
