export const Dumbbell = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 14.415L9.07504 8.14497C8.97865 7.70712 8.98162 7.25324 9.08373 6.81669C9.18584 6.38014 9.3845 5.97203 9.6651 5.62236C9.94569 5.27269 10.3011 4.99036 10.7052 4.79613C11.1092 4.60189 11.5517 4.50069 12 4.49997H24C24.4547 4.48931 24.9058 4.58214 25.3192 4.77144C25.7327 4.96075 26.0977 5.24157 26.3867 5.59269C26.6757 5.94381 26.8811 6.35603 26.9873 6.79819C27.0936 7.24035 27.0979 7.70088 27 8.14497L25.5 14.445"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.4997 25.5H22.4997M25.7697 31.5H10.2297C9.71732 31.5036 9.21257 31.3759 8.76356 31.129C8.31455 30.8822 7.93624 30.5245 7.66469 30.09C6.58646 28.2678 6.00957 26.1927 5.99257 24.0754C5.97558 21.9582 6.51909 19.8741 7.56793 18.0348C8.61676 16.1955 10.1336 14.6665 11.9644 13.6029C13.7952 12.5393 15.8749 11.9791 17.9922 11.9791C20.1095 11.9791 22.1891 12.5393 24.02 13.6029C25.8508 14.6665 27.3676 16.1955 28.4164 18.0348C29.4653 19.8741 30.0088 21.9582 29.9918 24.0754C29.9748 26.1927 29.3979 28.2678 28.3197 30.09C28.0495 30.5223 27.6735 30.8787 27.2274 31.1254C26.7812 31.3721 26.2795 31.501 25.7697 31.5V31.5Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
