import { Header } from "../../shared";
import { Advantage } from "../../features/location";

export const AdvantagePage = () => {
  return (
    <>
      <Header />
      <Advantage />
    </>
  );
};