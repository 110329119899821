export const Geo = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9998 13.5H27.4948C27.8543 13.4954 28.2036 13.6201 28.4789 13.8513C28.7542 14.0826 28.9373 14.4051 28.9948 14.76L31.4998 29.76C31.5349 29.9761 31.5222 30.1973 31.4627 30.4079C31.4031 30.6186 31.2982 30.8137 31.1553 30.9795C31.0123 31.1454 30.8348 31.2779 30.6352 31.3678C30.4356 31.4577 30.2187 31.5028 29.9998 31.5H5.99981C5.78091 31.5028 5.56403 31.4577 5.36442 31.3678C5.16481 31.2779 4.9873 31.1454 4.84435 30.9795C4.70141 30.8137 4.59649 30.6186 4.53697 30.4079C4.47745 30.1973 4.46477 29.9761 4.49981 29.76L7.00481 14.76C7.06231 14.4051 7.2454 14.0826 7.52072 13.8513C7.79604 13.6201 8.14528 13.4954 8.50481 13.5H11.9998"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24 10.5C24 8.9087 23.3679 7.38258 22.2426 6.25736C21.1174 5.13214 19.5913 4.5 18 4.5C16.4087 4.5 14.8826 5.13214 13.7574 6.25736C12.6321 7.38258 12 8.9087 12 10.5C12 16.5 18 22.5 18 22.5C18 22.5 24 16.5 24 10.5Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
